"use client";
import React, { useState } from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";
import Link from "next/link";
import { LINKS, MAIN_TEXT } from "@/utils/constants";

const NavBar = () => {
  const pathname = usePathname();
  // Define a piece of state to manage the visibility of the navbar menu
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  // Function to toggle the state
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <nav className="bg-white border-gray-200">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link
          href={LINKS.HOME}
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <Image
            className="w-full"
            src="/logo.webp"
            height={139}
            width={426}
            alt={MAIN_TEXT.TITLE}
            style={{ maxWidth: "213px" }}
          />
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-900 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded={isNavOpen ? "true" : "false"} // Reflect the state in aria-expanded
          onClick={toggleNav} // Attach the toggle function to the onClick event
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isNavOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link
                href={LINKS.HOME}
                className={`${
                  pathname === LINKS.HOME
                    ? "text-main-blue md:hover:bg-transparent"
                    : "text-gray-900 md:hover:text-white md:hover:bg-main-red"
                } block py-2 px-2 rounded hover:bg-gray-100 md:border-0`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                href={LINKS.ABOUT}
                className={`${
                  pathname === LINKS.ABOUT
                    ? "text-main-blue md:hover:bg-transparent"
                    : "text-gray-900 md:hover:text-white md:hover:bg-main-red"
                } block py-2 px-2 rounded hover:bg-gray-100  md:border-0`}
              >
                About
              </Link>
            </li>

            <li>
              <Link
                href={LINKS.CONTACT}
                className={`${
                  pathname === LINKS.CONTACT
                    ? "text-main-blue md:hover:bg-transparent"
                    : "text-gray-900 md:hover:text-white md:hover:bg-main-red"
                } block py-2 px-2 rounded hover:bg-gray-100 md:border-0`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
