export const LINKS = {
  HOME: "/",
  CONTACT: "/contact",
  ABOUT: "/about",
};

export const PHONE_INFO = {
  number: "14809554371",
  formattedNumber: "1 (480) 955-4371",
  ariaLabel: "Dial 1-480-955-4371 to speak with an agent",
};

export const BASIC_INFO = {
  licenseNumber: "18769 941",
  websiteUrl: "https://www.debramikus.com",
  websiteText: "www.debramikus.com",
  emailAddress: "debra@simplerhorizons.com",
  emailAriaLabel: "Send email to debra@simplerhorizons.com",
};

export const SOCIAL_LINKS = {
  applyLink: "https://www.planenroll.com/?purl=AxXUVX9n",
  formLink: "https://getform.io/f/pbmzzgxa",
};

export const MAIN_TEXT = {
  TITLE: "Simpler Horizons Insurance Solutions",
};
