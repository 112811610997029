"use client";
import { LINKS } from "@/utils/constants";
import Link from "next/link";
import { usePathname } from "next/navigation";

const Footer = () => {
  const pathname = usePathname();
  return (
    <footer className="w-[90vw]">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 pt-20">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 w-10/12	">
            <h2 className="mb-4 text-l font-bold tracking-tight leading-none md:text-xl lg:text-2xl text-main-red">
              DLM Insurance Services
            </h2>
            <h3 className="mb-4 text-m font-bold tracking-tight leading-none md:text-l lg:text-xl text-main-blue">
              Health Insurance Agent in San Tan Valley
            </h3>
            <p className="mb-3 text-main-gray text-xs	">
              By calling the number above you will be directed to a licensed
              insurance agent. Medicare has neither reviewed nor endorsed this
              information.
            </p>
            <p className="mb-3 text-main-gray text-xs	">
              Anthem Blue Cross is the trade name of Blue Cross of California.
              Independent licensee of the Blue Cross Association. ANTHEM is a
              registered trademark of Anthem Insurance Companies, Inc. The Blue
              Cross name and symbol are registered marks of the Blue Cross
              Association.
            </p>
            <p className="mb-3 text-main-gray text-xs	">
              In some states, all Medicare Supplement plans are offered to
              qualified individuals under the age of 65.
            </p>
          </div>
          <div className="grid gap-8">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-main-blue">
                Quick Links
              </h2>
              <ul className="text-gray-900 font-medium text-center flex flex-col items-end">
                <li className="mb-2">
                  <Link
                    href={LINKS.HOME}
                    className={`${
                      pathname === LINKS.HOME
                        ? "text-main-blue md:hover:bg-transparent"
                        : "text-gray-900 md:hover:text-white md:hover:bg-main-red"
                    } block py-2 px-2 rounded hover:bg-gray-100 md:border-0 `}
                  >
                    Home
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    href={LINKS.ABOUT}
                    className={`${
                      pathname === LINKS.ABOUT
                        ? "text-main-blue md:hover:bg-transparent"
                        : "text-gray-900 md:hover:text-white md:hover:bg-main-red"
                    } block py-2 px-2 rounded hover:bg-gray-100  md:border-0`}
                  >
                    About
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    href={LINKS.CONTACT}
                    className={`${
                      pathname === LINKS.CONTACT
                        ? "text-main-blue md:hover:bg-transparent"
                        : "text-gray-900 md:hover:text-white md:hover:bg-main-red"
                    } block py-2 px-2 rounded hover:bg-gray-100 md:border-0 `}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
